import emailjs from "@emailjs/browser";

emailjs.init("FuqNV8mUCsn7GdGTO");

export const emailConfig = {
  serviceId: "service_kq8hf5c",
  publicKey: "FuqNV8mUCsn7GdGTO",
  templates: {
    emailAdmin: "template_cguwdp2",
    emailCustomer: "template_e0qrz3g",
  },
};
