import CallbackSection from "./components/CallbackSection";
import CustomizationSection from "./components/CustomizationSection/CustomizationSection";
import GetQuote from "./components/GetQuote";
import Hero from "./components/Hero";
import Packages from "./components/Packages";
import PromoBanner from "./components/PromoBanner";
import Services from "./components/Services";

const Christmas = ({handleScheduleClick}) => {
  return (
    <>
      <section
        class="breakthrough-page-banner-area"
        style={{ maxHeight: "650px" }}
      >
        <Hero />
      </section>
      <div className="d-flex flex-column">
        <PromoBanner />
        <GetQuote handleScheduleClick={handleScheduleClick} />
        <Packages handleScheduleClick={handleScheduleClick} />
        <PromoBanner subText={true} />
        <CustomizationSection />
        <CallbackSection />
      </div>
    </>
  );
};

export default Christmas;
