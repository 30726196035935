import React, { useState } from "react";
import "./PopupForm.css";
import {
  sendAdminEmail,
  sendCustomerEmail,
} from "../Services/emailjs/emailService";

const PopupForm = ({ isOpen, onClose }) => {
  const initialFormData = {
    serviceType: "residential",
    companyName: "",
    firstName: "",
    lastName: "",
    streetAddress: "",
    city: "",
    phone: "",
    phoneType: "Cell",
    email: "",
    additionalInfo: "",
    smsConsent: false,
  };

  const [formType, setFormType] = useState("residential");
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
    setIsSubmitting(true);

    try {
      await sendAdminEmail({
        from_name: `${formData.firstName} ${formData.lastName}`,
        serviceType: formData.serviceType,
        companyName: formData.companyName,
        firstName: formData.firstName,
        lastName: formData.lastName,
        streetAddress: formData.streetAddress,
        city: formData.city,
        phone: formData.phone,
        phoneType: formData.phoneType,
        email: formData.email,
        additionalInfo: formData.additionalInfo,
        smsConsent: formData.smsConsent ? "Yes" : "No",
        formattedDate: new Date().toLocaleDateString(),
        reply_to: formData.email,
      });

      try {
        await sendCustomerEmail({
          from_name: "Breakthrough Handyman Services",
          formattedDate: new Date().toLocaleDateString(),
          customerName: `${formData.firstName} ${formData.lastName}`,
          user_email: formData.email,
          reply_to: "info@breakthroughhandyman.com",
        });

        setFormData(initialFormData);
        setFormType("residential");
        setSuccess(true);

        setTimeout(() => {
          const successMessage = document.querySelector(".success-message");
          if (successMessage) {
            successMessage.scrollIntoView({ behavior: "smooth" });
            successMessage.focus();
          }
        }, 100);

        setTimeout(() => {
          setSuccess(false);
          onClose();
        }, 3000);
      } catch (customerEmailError) {
        console.error(
          "Error sending customer confirmation:",
          customerEmailError
        );
        setError(
          "Your request was received, but we couldn't send you a confirmation email. We'll contact you soon."
        );
      }
    } catch (adminEmailError) {
      console.error("Error sending admin notification:", adminEmailError);
      setError("Failed to submit your request. Please try again!");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "serviceType") {
      setFormType(value.toLowerCase());
    }
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h2>Contact Us</h2>
        <p className="form-header">
          PLEASE FILL IN THE INFORMATION BELOW AND WE'LL GET BACK TO YOU SHORTLY
          <br />
          FOR FASTEST RESULTS, PLEASE CALL OUR OFFICE:{" "}
          <a href="tel:602-938-5415">602-938-5415</a>
        </p>

        <form onSubmit={handleSubmit}>
          {error && (
            <div
              style={{
                backgroundColor: "#ffebee",
                color: "#c62828",
                padding: "12px",
                marginBottom: "20px",
                borderRadius: "4px",
                border: "1px solid #ef9a9a",
                fontSize: "14px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              {error}
            </div>
          )}

          {success && (
            <div
              className="success-message"
              tabIndex={-1}
              role="alert"
              style={{
                backgroundColor: "#e8f5e9",
                color: "#2e7d32",
                padding: "12px",
                marginBottom: "20px",
                borderRadius: "4px",
                border: "1px solid #a5d6a7",
                fontSize: "24px",
                lineHeight: "1.5",
                textAlign: "center",
              }}
            >
              Thank you! Your request has been submitted successfully. We'll
              contact you soon.
            </div>
          )}

          <div className="service-type">
            <label className="radio-label">
              <input
                type="radio"
                name="serviceType"
                value="residential"
                checked={formType === "residential"}
                onChange={handleChange}
              />
              Residential
            </label>
            <label className="radio-label">
              <input
                type="radio"
                name="serviceType"
                value="commercial"
                checked={formType === "commercial"}
                onChange={handleChange}
              />
              Commercial
            </label>
          </div>

          {formType === "commercial" && (
            <div className="form-group">
              <label>Company Name: *</label>
              <input
                type="text"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                required={formType === "commercial"}
              />
            </div>
          )}

          <div className="form-row">
            <div className="form-group">
              <label>First Name: *</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Last Name: *</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label>Street Address: *</label>
            <input
              type="text"
              name="streetAddress"
              value={formData.streetAddress}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>City: *</label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Phone Number: *</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Phone Type:</label>
              <select
                name="phoneType"
                value={formData.phoneType}
                onChange={handleChange}
              >
                <option value="Home">Home</option>
                <option value="Cell">Cell</option>
                <option value="Work">Work</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <label>Email Address: *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Additional Information:</label>
            <textarea
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              rows="4"
            />
          </div>

          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="smsConsent"
                checked={formData.smsConsent}
                onChange={handleChange}
              />
              Yes, please contact me via SMS about my quote.
            </label>
            <p className="consent-text">
              By checking the box and clicking SUBMIT below, you're giving us
              permission to communicate with you via SMS text. We promise to
              only communicate when necessary for things regarding your quote
              request, reminders or an occasional special offer. No purchase is
              required. Plus, if you ever change your mind and you no longer
              want us to contact you, reply END or STOP to any email or text
              message to immediately opt out. Standard text message rates apply.
            </p>
          </div>

          <button
            type="submit"
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;
