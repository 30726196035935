import emailjs from "@emailjs/browser";
import { emailConfig } from "./emailConfig";

export const sendTemplateEmail = async (templateId, templateParams) => {
  try {
    const response = await emailjs.send(
      emailConfig.serviceId,
      templateId,
      templateParams,
      emailConfig.publicKey
    );
    return response;
  } catch (error) {
    console.error("Failed to send email:", error);
    throw new Error("Failed to send email");
  }
};

export const sendAdminEmail = async (templateParams) => {
    try {
      return sendTemplateEmail(
        emailConfig.templates.emailAdmin,
        templateParams
      );
    } catch (error) {
      console.error("Error sending Admin notification:", error);
      throw new Error("Failed sending Admin notification");
    }
};


export const sendCustomerEmail = async (templateParams) => {
    try {
      return sendTemplateEmail(
        emailConfig.templates.emailCustomer,
        templateParams
      );
    } catch (error) {
      console.error("Error sending Customer notification:", error);
      throw new Error("Failed sending Customer notification");
    }
};
