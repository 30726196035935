import React from "react";
import "./terms.css";

export default function Terms() {
  return (
    <div>
      <div
        className="container"
        style={{ marginTop: "200px", marginBottom: "50px" }}
      >
        <div className="pae-inner-content">
          <div className="text-center my-5 mt-8">
            <h1 className="display-4 mb-4">
              TERMS & CONDITIONS FOR BREAKTHROUGH HANDYMAN SERVICES
            </h1>
          </div>

          {/* 1. Introduction */}
          <section className="mb-5">
            <h2 className="text-center my-5">
              <span className="border-yellow-bottom">1. INTRODUCTION</span>
            </h2>
            <p>
              Welcome to Breakthrough Handyman Services LLC ("Company," "we,"
              "us," or "our"). By scheduling a service with us, you ("Customer")
              agree to the following Terms and Conditions, which outline the
              policies, procedures, and legal disclosures governing our handyman
              services.
            </p>
            <p>
              We are a licensed Limited Liability Company (LLC) in the state of
              Arizona and operate with independent 1099 handymen who are
              contracted to perform services under our established policies and
              procedures. Customers are responsible for reading and
              understanding these terms prior to scheduling services.
            </p>
          </section>

          {/* 2. Nature of Services */}
          <section className="mb-5">
            <h2 className="text-center my-5">
              <span className="border-yellow-bottom">
                2. NATURE OF SERVICES
              </span>
            </h2>
            <p>
              Breakthrough Handyman Services provides general handyman work,
              which includes but is not limited to minor home repairs,
              installations, and maintenance. We do not perform work that
              requires a general contractor's license or permits, including:
            </p>
            <ul>
              <li>Structural modifications</li>
              <li>Electrical panel replacements</li>
              <li>Major plumbing repairs (e.g., re-piping, main line work)</li>
              <li>
                Roofing or any work requiring ladder access above two stories
              </li>
              <li>
                Any work exceeding $1,000 per job per Arizona handyman exemption
                laws
              </li>
            </ul>
            <p>
              Customers acknowledge that our handymen are not licensed
              contractors and that work exceeding legal handyman limits must be
              referred to a licensed contractor.
            </p>
          </section>

          {/* 3. Independent 1099 Handymen */}
          <section className="mb-5">
            <h2 className="text-center my-5">
              <span className="border-yellow-bottom">
                3. INDEPENDENT 1099 HANDYMEN
              </span>
            </h2>
            <ul>
              <li>
                All handymen providing services through Breakthrough Handyman
                Services are independent contractors (1099), not employees of
                the company.
              </li>
              <li>
                Each handyman warrants and guarantees their own work under the
                Handyman 10-Step Process.
              </li>
              <li>
                The Company serves as a scheduling and service management
                platform but does not directly employ handymen.
              </li>
              <li>
                Customers acknowledge that handymen set their own schedules
                within Company guidelines and are responsible for their own
                tools, transportation, and insurance.
              </li>
            </ul>
          </section>

          {/* 4. Fees, Rates & Payment Policies */}
          <section className="mb-5">
            <h2 className="text-center my-5">
              <span className="border-yellow-bottom">
                4. FEES, RATES & PAYMENT POLICIES
              </span>
            </h2>

            <h3 className="mt-4">4.1 Hourly Rates</h3>
            <div className="row">
              <div className="col-md-6">
                <h4>1-Person Rate:</h4>
                <ul>
                  <li>1 Hour Minimum Call: $99</li>
                  <li>2-3 Hour Call: $89/hour</li>
                  <li>4-6 Hour Call: $85/hour</li>
                  <li>7-8 Hour Call: $79/hour</li>
                  <li>9-12 Hour Call: $75/hour</li>
                </ul>
              </div>
              <div className="col-md-6">
                <h4>2-Person Rate:</h4>
                <ul>
                  <li>1 Hour Minimum: $195</li>
                  <li>2-3 Hour Call: $170 per hour</li>
                  <li>4-6 Hour Call: $160 per hour</li>
                  <li>
                    7 Hour Call Max: $1000 ($142.85 per hour, our best value)
                  </li>
                </ul>
              </div>
            </div>

            <h3 className="mt-4">4.2 Payment Terms</h3>
            <ul>
              <li>
                Payment is due immediately upon job completion and must be
                processed while the handyman is still on-site.
              </li>
              <li>
                Payments are only accepted via debit or credit card through
                Stripe in our Customer Factor system.
              </li>
              <li>
                No cash, checks, Venmo, Zelle, or other third-party payment apps
                are accepted.
              </li>
            </ul>

            <h3 className="mt-4">4.3 Cancellation & Immediate Late Fees</h3>
            <p>Less than 24-Hour Cancellation Fee</p>
            <p>
              If a customer cancels or reschedules within 24 hours of their
              scheduled appointment, they will be charged one hour of service:
            </p>
            <ul>
              <li>$99 for a 1-hour call</li>
              <li>$85 for a multi-hour call</li>
              <li>
                Immediate Late Fee ($40): If payment is not processed within 20
                minutes of job completion
              </li>
            </ul>

            <h3 className="mt-4">4.4 On-Site Cancellation Fees</h3>
            <ul>
              <li>
                If a customer cancels after the handyman arrives, they will be
                charged the minimum 1-hour service rate.
              </li>
              <li>
                If the handyman must reschedule due to customer delays or site
                unavailability, this fee still applies.
              </li>
            </ul>
          </section>

          {/* 5. Handyman 10-Step Process & Materials Handling */}
          <section className="mb-5">
            <h2 className="text-center my-5">
              <span className="border-yellow-bottom">
                5. HANDYMAN 10-STEP PROCESS & MATERIALS
              </span>
            </h2>

            <h3 className="mt-4">5.1 How an Appointment Proceeds</h3>
            <ul>
              <li>
                Job verification upon arrival—handymen confirm job details and
                get permission before proceeding.
              </li>
              <li>
                Customers must be available at the end of the appointment for
                verification and payment.
              </li>
              <li>
                Handymen are paid for their time. Time is money, and job
                durations may vary.
              </li>
              <li>
                The office uses averages from 12,000+ past jobs to estimate time
                requirements.
              </li>
            </ul>

            <h3 className="mt-4">5.2 Materials Responsibility</h3>
            <ul>
              <li>
                The customer is responsible for providing all materials needed
                for the job.
              </li>
              <li>
                If additional materials are needed, the handyman will go to the
                store, and the customer must be available to pay for materials
                immediately.
              </li>
              <li>The company does not mark up materials.</li>
              <li>
                1 hour is naturally allotted for store runs, but actual time
                varies based on conditions.
              </li>
            </ul>

            <h3 className="mt-4">
              5.3 One-Hour Minimum Call & Service Commitment
            </h3>
            <ul>
              <li>
                The one-hour minimum call applies to all jobs in lieu of trip
                fees.
              </li>
              <li>
                If a job is completed early, the handyman will provide
                additional service within the hour (e.g., taking out trash,
                dusting, minor repairs) to ensure full value is delivered.
              </li>
              <li>
                Handymen pay for their own gas and time to get to customer
                locations.
              </li>
              <li>
                A handyman cannot control a job's exact requirements until they
                arrive on-site.
              </li>
            </ul>
          </section>

          {/* 6. Liability & Company Role */}
          <section className="mb-5">
            <h2 className="text-center my-5">
              <span className="border-yellow-bottom">
                6. LIABILITY & COMPANY ROLE
              </span>
            </h2>
            <ul>
              <li>
                The Company and its representatives do not perform handyman
                work.
              </li>
              <li>
                Breakthrough Handyman Services LLC only facilitates scheduling
                and service processes.
              </li>
              <li>
                The actual handyman work is performed solely by independent
                Handyman Professionals.
              </li>
              <li>
                The Company is not responsible for any actions, decisions, or
                work performed by the handyman.
              </li>
            </ul>
          </section>

          {/* 7. Contact Information */}
          <section className="mb-5">
            <h2 className="text-center my-5">
              <span className="border-yellow-bottom">
                7. CONTACT INFORMATION
              </span>
            </h2>
            <p>
              For any inquiries, cancellations, or disputes, customers may
              contact:
            </p>
            <ul>
              <li>
                Office Phone: <a href="tel:602-938-5415">602-938-5415</a> Ext 2
                for management
              </li>
              <li>
                Email:{" "}
                <a href="mailto:info@breakthroughhandyman.com">
                  info@breakthroughhandyman.com
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
}
