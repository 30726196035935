import Navbar from "./Components/Navbar/navbar";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import Home from "./Components/Home/home";
import CarePlans from "./Components/CarePlans/carePlans";
import Services from "./Components/Services/Services";
import Footer from "./Components/Footer/Footer";
import Careers from "./Components/Careers/careers";
import Testimonials from "./Components/Testimonials/testimonials";
import Privacy from "./Components/legal/privacy";
import Terms from "./Components/legal/terms";
import QueryForms from "./Components/legal/queryforms";
import Christmas from "./Components/Christmas/Christmas";
import PopupForm from "./Components/Forms/PopupForm";

function App() {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleScheduleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setIsFormOpen(true);
  };

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home handleScheduleClick={handleScheduleClick} />} />
        <Route exact path="/care-plans" element={<CarePlans handleScheduleClick={handleScheduleClick} />} />
        <Route exact path="/services" element={<Services handleScheduleClick={handleScheduleClick} />} />
        <Route exact path="/careers" element={<Careers />} />
        <Route exact path="/testimonials" element={<Testimonials />} />
        <Route exact path="/privacy-policy" element={<Privacy />} />
        <Route exact path="/terms-conditions" element={<Terms />} />
        <Route exact path="/form" element={<QueryForms />} />
        <Route exact path="/christmas" element={<Christmas handleScheduleClick={handleScheduleClick} />} />
      </Routes>
      <Footer />

      <button
        onClick={() => setIsFormOpen(true)}
        style={{
          position: "fixed",
          bottom: "20px",
          left: "20px",
          padding: "10px 20px",
          backgroundColor: "#007bff",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          zIndex: 999,
        }}
      >
        Contact Us
      </button>

      <PopupForm isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} />

      <script
        async
        type="module"
        src="https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js"
      ></script>
      <zapier-interfaces-chatbot-embed
        is-popup="true"
        chatbot-id="clyi737ft0018463a0e3sn3cq"
        height="600px"
        width="400px"
      ></zapier-interfaces-chatbot-embed>
    </div>
  );
}

export default App;
