import React from "react";
import residentialImage from "../../../assets/images/christmas/services/all_out_package.png";
import ServicePackage from "./common/ServicePackage";

const AllOutPackages = ({handleScheduleClick}) => {
  // const handleButtonClick = () => {
  //   window.open(
  //     "https://www.thecustomerfactor.com/bid.php?id=am9zaHVham1lNQ==",
  //     "_blank"
  //   );
  // };

  const packageDetails = {
    packageName: "All Out Package",
    items: [
      {
        image: residentialImage, // Path to the image file
        alt: "House decorated with Christmas lights",
      },
      "Complete holiday setup, including roofline, yard and more",
      "Up to 8 hours with two professionals or 16 man-hours max (capped at $1000)",
    ],
    price: 75,
    singleVisit: "$63.75/hr for 9+ hours",
    discounts: "(discounts apply for additional hours booked)",
    buttonText: "Schedule Basic Setup Today!",
    onButtonClick: handleScheduleClick,
  };

  return (
    <div>
      <ServicePackage
        packageName={packageDetails.packageName}
        items={packageDetails.items}
        buttonText={packageDetails.buttonText}
        onButtonClick={packageDetails.onButtonClick}
        imagePosition="right"
        price={packageDetails.price}
        singleVisit={packageDetails.singleVisit}
        discounts={packageDetails.discounts}
      />
    </div>
  );
};

export default AllOutPackages;
